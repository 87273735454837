import { h, Component, createRef, Fragment } from 'preact';
import { dateToString, secondsToTime } from "../server/components/utils";
import moment from 'moment';
import { Dialog } from "./dialog"
import { Tags } from './tags'
import { Medias, MediaPicture } from './medias'
import { PickDate, PleaseWait } from "./utils";
import md5 from 'md5';
import "./athlete.scss"
import __equipment_list from "./equipment.json"
import { fetchAPI } from '../libs/api'
import { Editor } from '@tinymce/tinymce-react'

let bodyPixNet = null
bodyPix.load({ architecture: 'MobileNetV1', outputStride: 16, multiplier: 0.75, quantBytes: 2 }).then(net => {
	bodyPixNet = net
});

export class Athlete extends Component {
	state = {
		athlete: null,
		tab: 'profile',
		working: false,
		savingBiography: false
	}

	focusModelIndex = -1
	athlete_id = 0
	athlete = null
	attributes = null
	equipment = []
	sponsors = []
	brands = []
	heights = []
	weights = []
	current_file_pic = null
	tabs = [
		{ tab: 'profile', name: 'Profile'},
		{ tab: 'sponsors', name: 'Sponsors'},
		{ tab: 'equipment', name: 'Equipment'},
		{ tab: 'social', name: 'Social'},
		{ tab: 'medias', name: 'Medias'},
		{ tab: 'results', name: 'Results'},
		{ tab: 'rankings', name: 'Rankings'},
	]
	attribute_list = [
		{ label: "Birthdate", name: "birthday", type: "date" },
		{ label: "Height (cm)", name: "height", type: "number", min: 120, max: 220 },
		{ label: "Weight (kg)", name: "weight", type: "number", min: 40, max: 120 },
	]
	social_list = [
		{ label: "Instagram", name: "instagram", type: "url" },
		{ label: "Twitter", name: "twitter", type: "url" },
		{ label: "YouTube", name: "youtube", type: "url", helperText: "Provide URL that includes Channel ID, not username.<br/>e.g. https://www.youtube.com/channel/UCsNo0w7jsTp_84dP0CGqZFQ" },
		{ label: "Facebook", name: "facebook", type: "url" },
		{ label: "Strava", name: "strava", type: "url" },
		{ label: "Zwift", name: "zwift", type: "url" },
		{ label: "TikTok", name: "tiktok", type: "url" },
		{ label: "Website", name: "website", type: "url" },
	]
	equipment_list = __equipment_list

	mediaManager = createRef()
	dialogSubmitAthletePic = createRef()
	imageSubmitAthletePic = createRef()
	transformImageSubmitAthletePic = createRef()
	maskSubmitAthletePic = createRef()
	scaleSubmitAthletePic = createRef()
	editImageSubmitAthletePic = createRef()
	tinymceBiography = createRef()

	onClickTab = e => {
		location.hash = "#"+e.target.dataset.tab
		this.setState({ tab: e.target.dataset.tab})
	}

	handleSaveChanges = e => {
		let attributes = []
		for(let a of this.attribute_list)
		{
			if(!a.updated) continue
			attributes.push({ name: a.name, value: a.value})
		}
		fetchAPI("/api/athlete/" + this.props.id + '/attributes', {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ attributes: attributes })
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
			})
	}

	fitImage = () =>  {
		if(!this.scaleSubmitAthletePic || !this.scaleSubmitAthletePic.current) return

		let scale = this.scaleSubmitAthletePic.current.value / 100
		let editWidth = this.editImageSubmitAthletePic.current.clientWidth
		let editHeight = this.editImageSubmitAthletePic.current.clientHeight
		let imgWidth = this.imageSubmitAthletePic.current.naturalWidth
		let imgHeight = this.imageSubmitAthletePic.current.naturalHeight

		let maskEditHeight = (editHeight - 40)
		let maskEditWidth = maskEditHeight

		if (maskEditWidth > editWidth) {
			maskEditWidth = maskEditHeight = editWidth - 24
		}

		this.maskSubmitAthletePic.current.style.height = maskEditHeight + "px"
		this.maskSubmitAthletePic.current.style.width = maskEditWidth + "px"

		let realWidth = 0, realHeight = 0

		if ((maskEditWidth / maskEditHeight) < (imgWidth / imgHeight)) {
			realHeight = (scale * maskEditHeight)
			realWidth = (scale * maskEditHeight * imgWidth / imgHeight)
		}
		else {
			realWidth = (scale * maskEditWidth)
			realHeight = (scale * maskEditWidth * imgHeight / imgWidth)
		}

		this.transformImageSubmitAthletePic.current.style.height = realHeight + "px"
		this.transformImageSubmitAthletePic.current.style.width = realWidth + "px"

		this.transformImageSubmitAthletePic.current.style.transform = `translate3d(${this.editImageSubmitAthletePic.current.imageTransformX}px, ${this.editImageSubmitAthletePic.current.imageTransformY}px, 0px)`

		let maskHeight = parseFloat(this.maskSubmitAthletePic.current.style.height)
		let imageHeight = this.imageSubmitAthletePic.current.naturalHeight
		let imageGrabHeight = imageHeight * maskHeight / realHeight

		if (imageGrabHeight < 600) {
			if (!this.maskSubmitAthletePic.current.classList.contains('danger')) {
				this.maskSubmitAthletePic.current.classList.add('danger')
			}
		}
		else {
			if (this.maskSubmitAthletePic.current.classList.contains('danger')) {
				this.maskSubmitAthletePic.current.classList.remove('danger')
			}
		}
	}

	imageToBlob(image, mime) {
		var byteString = atob(image.split(',')[1])
		var ab = new ArrayBuffer(byteString.length)
		var ia = new Uint8Array(ab)
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		var bb = new Blob([ab], { type: mime })
		return bb
	}

	_imageSubmitAthletePic = (d) => {
		this.imageSubmitAthletePic = { current: d }
	}

	dialogSaveProfileImage = (e, heroPrefix) => {
		let that = this

		let transformImageSubmitAthletePic = this.transformImageSubmitAthletePic.current
		let editImageSubmitAthletePic = this.editImageSubmitAthletePic.current
		let maskSubmitAthletePic = this.maskSubmitAthletePic.current
		let imageSubmitAthletePic = this.imageSubmitAthletePic.current

		let realWidth = parseFloat(transformImageSubmitAthletePic.style.width)
		let realHeight = parseFloat(transformImageSubmitAthletePic.style.height)

		let editHeight = editImageSubmitAthletePic.clientHeight
		let maskHeight = parseFloat(maskSubmitAthletePic.style.height)

		let imageWidth = imageSubmitAthletePic.naturalWidth
		let imageHeight = imageSubmitAthletePic.naturalHeight

		let imageCenterX = imageWidth * (-editImageSubmitAthletePic.imageTransformX / realWidth) + imageWidth * 0.5
		let imageCenterY = imageHeight * (-editImageSubmitAthletePic.imageTransformY / realHeight) + imageHeight * 0.5
		let imageGrabHeight = imageHeight * maskHeight / realHeight
		let imageGrabWidth = imageGrabHeight //* c.width / c.height

		let c = document.createElement('canvas')

		c.width = 600
		c.height = 600
		let context = c.getContext('2d', { alpha: true });
		context.clearRect(0, 0, c.width, c.height);
		context.rect(0, 0, c.width, c.height);
		context.drawImage(imageSubmitAthletePic, imageCenterX - imageGrabWidth * 0.5, imageCenterY - imageGrabHeight * 0.5, imageGrabWidth, imageGrabHeight, 0, 0, c.width, c.height)

		let mime = 'image/png'
		let png = heroPrefix ? e : c.toDataURL(mime)
		let blob = this.imageToBlob(png, mime)

		let hash = md5(png)

		this.setState({ working: true })
		let now = Date.now()
		let f = fetchAPI("/api/createuploadurl?md5=false", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: `${this.athlete.name.toLowerCase().replace(" ","-") }${heroPrefix ? `-${heroPrefix}` : ""}-profile.png`, size: blob.size, type: mime, lastModified: parseInt(now / 1000), md5: hash })
		})
			.then( response => response.json())
			.then(function(data_uploadurl) {
				let xhr = new window.XMLHttpRequest()
				xhr.open('put', data_uploadurl.put)
				xhr.onload = () => {
					fetchAPI("/api/convert-image", {
						method: "post",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ file: data_uploadurl })
					})
						.then(response => response.json())
						.then(data_convert => {
							let media = data_convert.media
							fetchAPI("/api/tag", {
								method: "post",
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify({ item_id: media.id, item_type: 'media', tag_ids: [that.athlete_id], tag_type: 'athlete' })
							})
								.then(() => {
									return fetchAPI("/api/athlete/" + that.athlete_id + "/attributes", {
										method: "post",
										headers: { 'Content-Type': 'application/json' },
										body: JSON.stringify({ attributes: [{ name: `${heroPrefix ? `${heroPrefix}-` : ""}pic`, value: media.id }] })
									})
								})
								.then(() => {
									if (heroPrefix) {
										if (heroPrefix == "hero-left") {
											that.athlete.media_hero_left = media
										} else if (heroPrefix == "hero-right") {
											that.athlete.media_hero_right = media
										}
									} else {
										that.athlete.media_pic = media
									}
									that.loadAthleteMedias()
									that.setState({ working: false })
								})
						})
				}
				xhr.onerror = () => { reject() }
				xhr.setRequestHeader('Content-Type', data_uploadurl.type)
				xhr.setRequestHeader('Cache-Control', data_uploadurl.cc)
				xhr.send(blob);
			})
	}

	handleOnInputScale = e => {
		this.fitImage()
	}

	onTransformImageDrag = e => {
		this.editImageSubmitAthletePic.current.imageTransformX += e.clientX - this.__editImageSubmitAthletePic__x__
		this.editImageSubmitAthletePic.current.imageTransformY += e.clientY - this.__editImageSubmitAthletePic__y__
		this.__editImageSubmitAthletePic__x__ = e.clientX
		this.__editImageSubmitAthletePic__y__ = e.clientY
		this.fitImage()
	}

	addMouseEvents() {
		let that = this
		this.__editImageSubmitAthletePic__x__ = 0
		this.__editImageSubmitAthletePic__y__ = 0
		this.editImageSubmitAthletePic.current.addEventListener("pointerdown", function(e) {
			that.__editImageSubmitAthletePic__x__ = e.clientX
			that.__editImageSubmitAthletePic__y__ = e.clientY
			document.addEventListener("pointerup", function(e) { document.removeEventListener("pointermove", that.onTransformImageDrag) }, {once: true});
			document.addEventListener("pointermove", that.onTransformImageDrag);
		});
	}

	handleOnLoad = e => {
		this.editImageSubmitAthletePic.current.imageTransformX = 0
		this.editImageSubmitAthletePic.current.imageTransformY = 0

		this.addMouseEvents()
		this.fitImage()
	}

	autocenter = e => {
		let that = this
		let imageSubmitAthletePic = this.imageSubmitAthletePic.current
		bodyPixNet.segmentPerson(imageSubmitAthletePic, {segmentationThreshold: 0.7})
			.then(segmentation => {
				if (segmentation.allPoses.length) {
					console.log(segmentation)
					let leftEye = segmentation.allPoses[0].keypoints.filter(x => x.part == "leftEar")
					let rightEye = segmentation.allPoses[0].keypoints.filter(x => x.part == "rightEar")
					if (leftEye && rightEye) {
						let delta_x = leftEye[0].position.x - rightEye[0].position.x
						let scale = (0.35 * segmentation.width) / delta_x
						that.scaleSubmitAthletePic.current.value = 100 * scale

						that.fitImage()
					}
				}
			});
	}

	removebg = e => {
		if (!this.current_file_pic) {
			return
		}
		let button = e.target
		let that = this
		button.textContent = "Loading..."
		var frb = new FileReader()
		frb.onload = function () {
			const formData = new FormData()

			button.textContent = "Processing..."

			formData.append('size', 'full')
			formData.append('format', 'png')
			formData.append('image_file', new Blob([frb.result]))

			fetch('https://api.remove.bg/v1.0/removebg', {
				method: "post",
				body: formData,
				headers: { 'X-Api-Key': "dUjgf5t7fsaGNqRTUeQ3EyVv" },
			})
				.then(response => {
					if(response.ok) {
						return response.blob()
					}
					return Promise.reject(response)
				})
				.then(image_data => {
					button.textContent = "Remove Background"
					that.imageSubmitAthletePic.current.src = URL.createObjectURL(image_data)
				})
				.catch(async (error) => {
					button.textContent = "Remove Background (Error)"
					let j = await error.json()
					console.error('Error:', j);
				});
		}
		frb.readAsArrayBuffer(this.current_file_pic)
		this.current_file_pic = null
	}

	handleUploadPicFile = (e, heroPrefix) => {
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.accept = "image/*"
		input.addEventListener("change", (e) => {
			that.current_file_pic = e.target.files[0]
			var fr = new FileReader()
			fr.onload = function () {
				if (!heroPrefix) {
					that.dialogSubmitAthletePic.current.show({
						shown: () => {
							that.imageSubmitAthletePic.current.src = fr.result
						}
					})
				}
			}
			fr.onloadend = function () {
				if (heroPrefix) {
					that.dialogSaveProfileImage(fr.result, heroPrefix)
				}
			}
			fr.readAsDataURL(e.target.files[0])
			
			e.target.value = ''
		})
		input.click()
	}

	onPicSubmission = e => {
		var link = document.createElement("a");
		link.download = this.athlete.link + "-submission." + e.target.src.split(".")[1];
		link.href = e.target.src;
		link.target = '_blank';
		link.click();
	}

	profile_save = []
	onInputProfile = e => {
		if(!e.target.validity.valid) {
			return
		}
		let attr = e.target.dataset.attr
		let value = e.target.value

		this.profile_save[attr] = value
		this.profile[attr] = value

		this.saveProfile()
	}

	saveProfile = (options) => {
		if(this.save_timeout) {
			clearTimeout(this.save_timeout)
		}
		this.save_timeout = setTimeout((that) => {
			let attributes = []
			for (let a in this.profile_save) {
				attributes.push({ name: a, value: this.profile_save[a] })
			}
			this.profile_save = []

			fetchAPI("/api/athlete/" + this.props.id + "/profile/attributes", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ attributes: attributes })
			})
				.then(function(response) {
					that.setState({
						savingBiography: false
					})

					if (response.ok) {
						return response.json();
					}

					return Promise.reject(response);
				})
				.then(function(data) { })

			that.save_timeout = null;
		}, 250, this);
	}

	onInputMember = e => {
		if (!e.target.validity.valid) {
			return
		}
		let attr = e.target.dataset.attr
		let value = e.target.value

		this.athlete[attr] = value

		if (value == '') {
			value = null
		}

		let members = []
		members.push({ name: attr, value: value })

		fetchAPI("/api/athlete/" + this.props.id + '/members', {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ members: members })
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
			})
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.athlete_id != nextProps.id) {
			this.loadAthlete(nextProps.id)
		}

		if (location.hash) {
			let tab = location.hash.substring(1)
			if (this.tabs.find(x => x.tab == tab)) {
				this.setState({ tab: tab })
			}
		}
	}

	componentDidMount() {
		for (let h = 150; h <= 200; h++) {
			let feet = h / 30.48
			this.heights.push({ name: h + "cm / " + parseInt(feet) + "'" + Math.ceil((feet % 1) * 12) , value: h})
		}
		for (let w = 45; w <= 100; w++) {
			let lbs = w / 0.45359237
			this.weights.push({ name: w + "kg / " + parseInt(lbs) + "lbs" , value: w})
		}
		this.loadAthlete(this.props.id)

		let that = this
		fetchAPI("/api/nations")
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
				that.setState({ nations: data.nations })
			})
	}

	loadAthleteMedias() {
		let that = this
		fetchAPI("/api/athlete/" + this.athlete_id + "/medias", {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
				that.medias = data.medias
				if (that.mediaManager.current) {
					that.mediaManager.current.fetchMedias()
				}
				that.setState({})
			})
	}

	loadAthlete(athlete_id) {
		let that = this

		this.athlete_id = athlete_id

		for (let a of this.attribute_list) {
			a.value = null
			a.updated = false
		}

		for (let e of this.equipment_list) {
			e.value = null
			e.brand = null
			e.model = null
			e.updated = false
		}

		fetchAPI("/api/athlete/" + athlete_id, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
				that.attributes = data.attributes
				for (let a of data.attributes) {
					let f = that.attribute_list.find(x => x.name == a.name)
					if (f) {
						f.value = a.value
					}
				}

				that.equipment = data.equipment
				for (let e of data.equipment) {
					let f = that.equipment_list.find(x => x.name == e.name)
					if (f) {
						f.brand = e.brand
						f.model = e.model
						f.value = e.value
					}
				}

				that.profile = data.profile

				that.brands = data.brands
				that.sponsors = data.sponsors

				that.medias = data.medias

				that.rankings = data.rankings
				that.results = data.results
				that.points = data.points
				that.athlete = data.athlete

				that.next_id = data.next_id
				that.prev_id = data.prev_id

				that.results_year = []
				let current_year = 0
				for (let res of that.results) {
					if (current_year != res.year) {
						that.results_year.push({ year: res.year, results: [] })
						current_year = res.year
					}
					that.results_year[that.results_year.length - 1].results.push(res)
				}
				that.setState({ })
			})
		window.addEventListener("resize", this.fitImage)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.fitImage);
	}

	dialogOnShow = e => {
	}

	dialogOnHide = e => {
	}

	onRemoveSubmission = e => {
		fetchAPI("/api/athlete/" + this.props.id + '/attributes', {
			method: "delete",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ attributes: [{ name: "pic-submission"}] })
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {

			})

		this.attributes = this.attributes.filter(x => x.name != "pic-submission")
		this.setState({})
	}

	save = (options) => {
		let that = this

		if (this.save_timeout) {
			clearTimeout(this.save_timeout)
		}
		this.save_timeout = setTimeout((that) => {
			let equipment = this.equipment_list.filter(x => x.dirty).map(x => {
				x.dirty = false
				return { name: x.name, value: x.value, brand: x.brand, model: x.model }
			})
			if (equipment.length) {
				fetchAPI("/api/athlete/" + this.props.id + "/equipment", {
					method: "post",
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ equipment: equipment })
				})
					.then(function(response) {
						if(response.ok) {
							return response.json()
						}
						return Promise.reject(response);
					})
					.then(function(data) { })
			}

			if (options && options.sponsors) {
				fetchAPI("/api/athlete/" + this.props.id + "/sponsors", {
					method: "post",
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ sponsors: this.sponsors })
				})
					.then(function(response) {
						if (response.ok) {
							return response.json()
						}
						return Promise.reject(response)
					})
					.then(function(data) { })
				console.log('save sponsors')
			}

			console.log("2",this.sponsors.length,this.sponsors)

			that.save_timeout = null;
		}, 250, this);
	}

	moveSponsor = (e, dir) => {
		let index = -1
		const path = e.path || (e.composedPath && e.composedPath());
		for (let elem of path) {
			if (elem.dataset.index != undefined) {
				index = parseInt(elem.dataset.index)
				break
			}
		}
		if (index == -1) {
			return
		}
		if (dir == 1) {
			let _t = this.sponsors[index - 1]
			this.sponsors[index - 1] = this.sponsors[index]
			this.sponsors[index] = _t
		}
		else {
			let _t = this.sponsors[index + 1]
			this.sponsors[index + 1] = this.sponsors[index]
			this.sponsors[index] = _t
		}
		this.save({ sponsors: true })
		this.setState({})
	}

	deleteSponsor = e => {
		let index = -1
		const path = e.path || (e.composedPath && e.composedPath());
		for (let elem of path) {
			if (elem.dataset.index != undefined) {
				index = parseInt(elem.dataset.index)
				break
			}
		}
		if (index == -1) return
		this.sponsors = this.sponsors.filter((x, _index) => _index != index)

		console.log("1",this.sponsors.length,this.sponsors)

		this.save({ sponsors: true })
		this.setState({})
	}

	createProfile = e => {
		let that = this
		fetchAPI("/api/athlete/" + this.props.id + "/createprofile", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
				that.loadAthlete(that.props.id)
			})
	}

	createAccount = e => {
		fetchAPI("/auth/create_athlete_account/" + this.props.id, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function(data) {
			})
	}

	reassign = e => {
		let that = this
		let result_id = e.target.dataset.resultid
		let athlete_id = window.prompt("Enter the ID of the Athlete you want to re assign this result to.")
		fetchAPI("/api/result/reassign/" + result_id + "/" + athlete_id, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if(response.ok) {
					return response.json()
				}
				return Promise.reject(response)
			})
			.then(function(data) {
				if (data.status == 0) {
					for (let ry of that.results_year) {
						ry.results = ry.results.filter(x => x.id != result_id)
					}
					that.setState({})
					window.alert("The result was reassign to: " + data.athlete.first + " " + data.athlete.last)
				}
				else if(data.status == 1) {
					window.alert("Couldn't find the athlete with ID: " + athlete_id)
				}
				else if(data.status == 2) {
					window.alert("Athlete with ID " + athlete_id + " has already a result in this race.")
				}
			})
		console.log(result_id, athlete_id)
	}

	handleSaveBiography = (e) => {
		const value = this.tinymceBiography.current.getContent()
		this.profile_save['bio'] = value
		this.profile['bio'] = value

		this.setState({
			savingBiography: true
		})
		this.saveProfile()
	}

	render(props, state) {
		if (!this.athlete) {
			return(<div>Loading...</div>)
		}

		let athlete = this.athlete
		let profile = this.profile
		let attributes = this.attributes
		let image = athlete.media_pic
		let heroLeftImage = athlete.media_hero_left
		let heroRightImage = athlete.media_hero_right

		let picSubmission = attributes.find(x => x.name == "pic-submission")
		let heroLeftSubmission = attributes.find(x => x.name == "hero-left-submission")
		let heroRightSubmission = attributes.find(x => x.name == "hero-right-submission")

		let emails = profile?.manager?.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)

		// tinymce
		const tinymceInit = {
			skin: 'oxide-dark',
			content_css: 'dark',
			height: 500,
			menubar: false,
			plugins: [
				'advlist',
				'autolink',
				'lists',
				'link',
				'searchreplace',
				'fullscreen',
				'code'
			],
			toolbar: 'undo redo | styles bold bullist numlist link unlink fullscreen code',
			styles: {
				p: { block: "p" },
				h3: { block: "h3" },
				h4: { block: "h4" },
				small: { block: "small" },
			},
			style_formats: [
				{ title: "H3", format: "h3" },
				{ title: "H4", format: "h4" },
				{ title: "Paragraph", format: "p" },
				{ title: "Small", format: "small" },
			],
			link_title: true,
			target_list: true,
			relative_urls: false,
			remove_script_host : true,
		}

		return(
			<div class='athlete-info'>
				<div key={'athlete-' + athlete.id}>
					<div class='d-flex justify-content-between align-items-center'>
						<h2 class='headline'>{athlete.name}</h2>
						<div class=''>
							{ this.prev_id && <a class='btn btn-sm btn-outline-light px-3' href={"/athlete/" + this.prev_id}><i class="fas fa-chevron-left"></i></a> }
							{ this.next_id && <a class='btn btn-sm btn-outline-light px-3 ml-1' href={"/athlete/" + this.next_id}><i class="fas fa-chevron-right"></i></a> }
						</div>
					</div>
					<div class='tabs'>
						{this.tabs.map(t => {
							return (<div class='tab' selected={t.tab == state.tab} data-tab={t.tab} onClick={this.onClickTab}>{t.name}</div>)
						})}
					</div>

					{ state.tab == 'profile' &&
						<div class='card'>
							<table class='athlete-table'>
								<tr>
									<th>ID</th>
									<td>{athlete.id}</td>
								</tr>
								<tr>
									<th>First</th>
									<td>
										<input class="form-control form-control-sm" onInput={this.onInputMember} onChange={this.onInputMember}
											   value={athlete.first}
											   data-attr="first"
										/>
									</td>
								</tr>
								<tr>
									<th>Last</th>
									<td>
										<input class="form-control form-control-sm" onInput={this.onInputMember} onChange={this.onInputMember}
											   value={athlete.last}
											   data-attr="last"
										/>
									</td>
								</tr>
								<tr>
									<th>Country</th>
									<td>
										{ state.nations &&
											<select class='form-control form-control-sm' onChange={this.onInputMember} data-attr="country,country_3,country_id">
												{ state.nations.map(x => { return(<option value={[x.alpha_2,x.alpha_3,x.id].join(",")} selected={x.alpha_2 == athlete.country}>{x.name}</option>) })}
											</select>
										}
									</td>
								</tr>
								<tr><th>Division</th><td>{athlete.division}</td></tr>
								{ !profile &&
									<tr><th></th><td>
										<div class='alert alert-danger'>THIS ATHLETE DOESN'T HAVE A PTO PROFILE</div>
										<button class='btn btn-sm btn-danger' onClick={this.createProfile}>Click here to create a profile for this athlete</button>
									</td></tr>
								}
								{ profile &&
									<Fragment>
										<tr>
											<th>Email</th>
											<td>
												<input class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile.email}
													   data-attr="email"
												/>
											</td>
											<td>
												{ profile.email && <a class='btn btn-sm btn-dark' href={"mailto:" + profile.email}><i class="fas fa-envelope-square"></i></a>}
											</td>
										</tr>
										<tr>
											<th>Phone Number</th>
											<td>
												<input class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile.phone_number}
													   data-attr="phone_number"
														type="tel"
												/>
											</td>
										</tr>
										<tr>
											<th>Birthday</th>
											<td>
												<PickDate class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
														  _type="date"
														  value={profile.dob && profile.dob.split("T")[0]}
														  data-attr="dob"
												/>
											</td>
										</tr>
										<tr>
											<th>Height</th>
											<td>
												<div class='select'>
													<select class='form-control form-control-sm' data-attr="height" onChange={this.onInputProfile}>
														<option value='0'>Select Height</option>
														{ this.heights.map(x => <option value={x.value} selected={x.value == profile.height}>{x.name}</option>) }
													</select>
												</div>
											</td>
										</tr>
										<tr>
											<th>Weight</th>
											<td>
												<div class='select'>
													<select class='form-control form-control-sm' data-attr="weight" onChange={this.onInputProfile}>
														<option value='0'>Select Weight</option>
														{ this.weights.map(x => <option value={x.value} selected={x.value == profile.weight}>{x.name}</option>) }
													</select>
												</div>
											</td>
										</tr>
										<tr>
											<th>Nicknames</th>
											<td>
												<input class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile.nicknames}
													   data-attr="nicknames"
												/>
											</td>
										</tr>
										<tr>
											<th>Alternate names</th>
											<td>
												<input class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile.alternate_names}
													   data-attr="alternate_names"
												/>
											</td>
										</tr>
									</Fragment>
								}
								<tr>
									<th>Profile Pic</th>
									<td>
										<div class='d-flex'>
											<div class='profile-pic'>
												<MediaPicture media={image} sizes="160px"/>
											</div>
											{ picSubmission &&
												<div class='pic-submission'>
													<a href={picSubmission.value} download="test.png" target="_blank"><img onClick={this.onPicSubmission_} class='' src={picSubmission.value}/></a>
													<button class='btn btn-sm btn-danger ml-2' onClick={this.onRemoveSubmission}>Remove</button>
												</div>
											}
										</div>

										<div class='mt-1'>
											<button class='btn btn-secondary btn-sm' onClick={this.handleUploadPicFile}>Upload File</button>
											<button class='btn btn-secondary btn-sm ml-1'>Upload Url</button>
										</div>
									</td>
								</tr>
								<tr>
									<th className='pt-2 align-top'>Hero Image left</th>
									<td>
										<div class='d-flex'>
											<div class='profile-pic'>
												<MediaPicture media={heroLeftImage} sizes="160px"/>
											</div>
											{ heroLeftSubmission &&
												<div class='pic-submission'>
													<a href={heroLeftSubmission.value} download="test.png" target="_blank"><img onClick={this.onPicSubmission_} class='' src={heroLeftSubmission.value}/></a>
													<button class='btn btn-sm btn-danger ml-2' onClick={this.onRemoveSubmission}>Remove</button>
												</div>
											}
										</div>

										<div class='mt-1'>
											<button class='btn btn-secondary btn-sm' onClick={(event) => this.handleUploadPicFile(event, "hero-left")}>Upload File</button>
										</div>
										<p><em><small>
										Minimum dimensions of 1500x1000px
										<br />
										Example:
										</small></em></p>
										<img src='/assets/athlete-hero-left-example.png' alt='Outline of an athlete facing to the left' width={170} className='pb-2' />
									</td>
								</tr>
								<tr>
									<th className='pt-2 align-top'>Hero Image Right</th>
									<td>
										<div class='d-flex'>
											<div class='profile-pic'>
												<MediaPicture media={heroRightImage} sizes="160px"/>
											</div>
											{ heroRightSubmission &&
												<div class='pic-submission'>
													<a href={heroRightSubmission.value} download="test.png" target="_blank"><img onClick={this.onPicSubmission_} class='' src={heroRightSubmission.value}/></a>
													<button class='btn btn-sm btn-danger ml-2' onClick={this.onRemoveSubmission}>Remove</button>
												</div>
											}
										</div>

										<div class='mt-1'>
											<button class='btn btn-secondary btn-sm' onClick={(event) => this.handleUploadPicFile(event, "hero-right")}>Upload File</button>
										</div>
										<p><em><small>
										Minimum dimensions of 1500x1000px
										<br />
										Example:
										</small></em></p>
										<img src='/assets/athlete-hero-right-example.png' alt='Outline of an athlete facing to the right'  width={170} className='pb-2' />
									</td>
								</tr>
								<tr>
									<th valign="top">Retirement Date</th>
									<td>
										<PickDate class="form-control form-control-sm" onInput={this.onInputMember} onChange={this.onInputMember}
												  _type="date"
												  value={athlete.retirement_date && athlete.retirement_date.split("T")[0]}
												  data-attr="retirement_date"
										/>
										<p><em><small>
											When you next Sync to Rankings Website, any athletes who have not raced within the last 104 weeks will be marked as retired.<br />Their retirement date will be set to the day of their last race 104 weeks ago.
										</small></em></p>
									</td>
								</tr>
								{ profile &&
									<Fragment>
										<tr>
											<th>Maternity Leave</th>
											<td>
												<input class="form-control form-control-sm" placeholder="YYYY-MM-DD" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile.maternity_leave}
													   data-attr="maternity_leave"
												/>
											</td>
										</tr>
										<tr>
											<th>Manager/Agent</th>
											<td>
											<textarea className="form-control form-control-sm"
													  onInput={this.onInputProfile} onChange={this.onInputProfile}
													  value={profile.manager}
													  data-attr="manager"
													  rows="3"
											/>
											</td>
											<td>
												{emails && emails.length &&
													<a className='btn btn-sm btn-dark' href={"mailto:" + emails[0]}><i
														className="fas fa-envelope-square"></i></a>}
											</td>
										</tr>
										<tr>
											<th>Biography</th>
											<td colspan="3" className='tinymce-wrap'>
												<Editor
													apiKey='sylfy7jhimr43zdamjoocerkv5jsa5sek62uv3mxsp0xyd3o'
													onInit={(evt, editor) => this.tinymceBiography.current = editor}
													init={tinymceInit}
													initialValue={profile.bio}
												/>
												<button id='athlete-save-biography' className='btn btn-sm btn-warning my-2' disabled={state.savingBiography} onClick={this.handleSaveBiography}>Save Biography</button>
											</td>
										</tr>
										<tr>
											<th>Short Biography</th>
											<td colspan="3">
												<textarea className="form-control form-control-sm"
													onInput={this.onInputProfile} onChange={this.onInputProfile}
													value={profile.bio_short}
													data-attr="bio_short"
													rows="4"
												/>
											</td>
										</tr>
									</Fragment>
								}

								{ false && this.attribute_list.map(at => {
									if(at.type == "select")
									{
										return(
											<tr>
												<th>{at.label}</th>
												<td>
													<div class='d-flex select'>
														<select class="form-control form-control-sm" data-label={at.label} data-name={at.name} data-original={at.value}>
															<option value=""></option>
															{ at.options.map(option => {
																return(<option value={option.id} selected={at.value == option.id}>{option.name}</option>)
															})}
														</select>
													</div>
												</td>
											</tr>
										)
									}
									else if(at.type == "tags")
									{
										return(
											<tr>
												<th>{at.label}</th>
												<td>
													<Tags value={at.value} placeholder="Add a sponsor..." options={this.brands} onChange={ v => { at.value = v; at.updated = true; } } />
												</td>
											</tr>
										)
									}
									return (
										<tr>
											<th>{at.label}</th>
											<td>
												<div class='d-flex'>
													<input type={at.type || "text"} placeholder={at.type == "url" ? "Type/copy an url link" : ""} min={at.min} max={at.max} class="form-control form-control-sm" data-label={at.label} data-name={at.name} data-original={at.value} value={at.value} onChange={ e => { at.value = e.target.value; at.updated = true; } }/>
													<a class='btn btn-sm btn-dark d-flex align-items-center ml-1' href={'https://www.google.com/search?q=' + (athlete.name + " " + at.label).split(' ').join('+')} target='_search'><div class='material-icons-round' style='font-size: 16px;'>search</div></a>
												</div>
											</td>
										</tr>
									)
								})}
							</table>
							<button class='btn btn-primary mt-4 d-none' onClick={this.handleSaveChanges}>Save Changes</button>
						</div>
					}

					{ state.tab == 'social' &&
						<div class='card'>
							<table class='social-table'>
								{ profile && this.social_list.map((at, index) => {
									return (
										<tr>
											<th>{at.label}</th>
											<td>
												<input class="form-control form-control-sm" onInput={this.onInputProfile} onChange={this.onInputProfile}
													   value={profile[at.name]}
													   data-attr={at.name}
													   type="url"
													   placeholder="Type/copy an url link"
												/>

												{at.helperText && (
													<p>
														<em>
															<small>
																{at.helperText.split('<br/>').map((item, i) => {
																	return (
																		<span key={i}>
																			{item}
																			<br/>
																		</span>
																	)
																})}
															</small>
														</em>
													</p>
												)}
											</td>
											<td>
												<a class='btn btn-sm btn-dark d-flex align-items-center ml-1'
												   href={'https://www.google.com/search?q=' + (athlete.name + " " + at.label).split(' ').join('+')}
												   target='_search'>
													<div class='material-icons-round' style='font-size: 16px;'>search</div>
												</a>
											</td>
											<td>
												{profile[at.name] && profile[at.name] != '' && <a class='btn btn-sm btn-dark' target='_blank' href={profile[at.name]}><i class="fas fa-external-link-alt"></i></a>}
											</td>
										</tr>
									)
								})}
							</table>
						</div>
					}

					{ state.tab == 'sponsors' &&
						<div class='card'>
							<div class='sponsor-add'>
								<Tags value="" placeholder="Add a sponsor..." options={this.brands} mode="select" onChange={ v => {
									this.sponsors.push({ brand_id: parseInt(v) })
									this.save({ sponsors: true })
									this.setState({})
								} } />
							</div>
							<table class='sponsor-table'>
								{this.sponsors.map((sponsor, index) => {
									let brand = this.brands.find(x => x.id == sponsor.brand_id)
									if(!brand) return
									return(<tr>
										<td>
											{ index != 0 && <button class='btn btn-sm btn-link' data-index={index} onClick={(e) => { this.moveSponsor(e, 1)}}><i class="fas fa-arrow-up"></i></button> }
											{ (index != this.sponsors.length - 1) && <button class='btn btn-sm btn-link' data-index={index} onClick={(e) => { this.moveSponsor(e, -1)}}><i class="fas fa-arrow-down"></i></button> }
										</td>
										<td><div class='image-container'><img src={brand.logo}/></div></td>
										<td>{brand.name}</td>
										<td><button class='btn btn-danger' data-index={index} onClick={this.deleteSponsor}><i class="fas fa-trash-alt no-pointer-event"></i></button></td>
									</tr>)
								})}
							</table>
						</div>
					}

					{ state.tab == 'equipment' &&
						<div class='card'>
							<table class='equipment-table'>
								{ this.equipment_list.map((eq, index) => {
									if(eq.type == "title")
									{
										return(<tr><th><h3 class='mt-4'>{eq.label}</h3></th></tr>)
									}
									if(eq.type == "select")
									{
										return(
											<tr key={["equipment", athlete.id, index].join("-")}>
												<th>{eq.label}</th>
												<td>
													<div class='d-flex select'>
														<select class="form-control form-control-sm"
																data-index={index}
																onChange={ (e) => {
																	this.equipment_list[e.target.dataset.index].value = e.target.value;
																	this.equipment_list[e.target.dataset.index].dirty = true;
																	this.save()
																}}>
															<option value="">-</option>
															{ eq.options.map(option => {
																return(<option value={option.name} selected={eq.value == option.name}>{option.label}</option>)
															})}
														</select>
													</div>
												</td>
											</tr>
										)
									}
									return(<tr key={["equipment", athlete.id, index].join("-")}>
										<th>{eq.label}</th>
										<td>
											<Tags value={eq.brand}
												  placeholder="Type a brand..."
												  options={this.brands}
												  mode="unique"
												  index={index}
												  onChange={ (v, comp) => {
													  this.equipment_list[comp.props.index].brand = v == "" ? null : v;
													  this.equipment_list[comp.props.index].dirty = true;
													  this.focusModelIndex = comp.props.index
													  this.setState({}, () => {
														  if(this.focusModelIndex != -1)
														  {
															  if(this.equipment_list[this.focusModelIndex].modelInput)
															  {
																  this.equipment_list[this.focusModelIndex].modelInput.focus()
															  }
															  this.focusModelIndex = -1
														  }
													  })
													  this.save()
												  } }
											/>
										</td>
										<td>
											{ eq.brand &&
												<input class="form-control form-control-sm"
													   placeholder="Add model or extra information"
													   data-index={index}
													   onInput={ e => {
														   this.equipment_list[e.target.dataset.index].model = e.target.value;
														   this.equipment_list[e.target.dataset.index].dirty = true;
														   this.save()
													   }}
													   ref={ r => { this.equipment_list[index].modelInput = r }}
												/>
											}
										</td>
									</tr>)
								})}
							</table>
						</div>
					}
					{ state.tab == 'medias' &&
						<Fragment key={["medias", athlete.id].join("-")}>
							<Medias ref={this.mediaManager} fetchMedias={(callback) => {
								callback(this.medias)
							}}/>
						</Fragment>
					}

					{ state.tab == 'results' &&
						<Fragment>
							{ this.results_year.map(y =>
								<div class='card'>
									<table class='results-table'>
										<tr><td colspan="100"><h3 class='mb-0'>{y.year}</h3></td></tr>
										<tr>
											{ ["Date", "Ranking System", "Place", "Race", "Swim", "Bike", "Run", "Overall", "Points"].map(x => <th>{x}</th>)}
										</tr>
										{ y.results.map((r, index) => {
											let points = this.points.find(x => x.result_id == r.id)
											return(
												<tr>
													<td>{ dateToString(r.date) }</td>
													<td>{ r.ranking_system }</td>
													<td>{r.rank == 99999 ? r.status : r.rank}</td>
													<td><a href={'/race/' + r.race_id + "#results"}>{r.serie}</a></td>
													<td>{secondsToTime(r.swim)}</td>
													<td>{secondsToTime(r.bike)}</td>
													<td>{secondsToTime(r.run)}</td>
													<td>{secondsToTime(r.overall)}</td>
													<td>{ points && points.overall_points && points.overall_points.toFixed(2) }</td>
													<td><button class='btn btn-sm btn-secondary' onClick={this.reassign} data-resultid={r.id}>Re-Assign</button></td>
												</tr>
											)
										})}
									</table>
								</div>

							) }
						</Fragment>
					}

					{ state.tab == 'rankings' &&
						<Fragment>
							<div class='card'>
								<table class='table text-center'>
									<tr>
										<th>Date</th>
										<th>Rank</th>
										<th>Points</th>
										<th>Swim Rank</th>
										<th>Swim Points</th>
										<th>Bike Rank</th>
										<th>Bike Points</th>
										<th>Run Rank</th>
										<th>Run Points</th>
									</tr>
									{ this.rankings.map(r => {
										return (<tr>
											<td>{moment.utc(r.date).format("YYYY-MM-DD")}</td>
											<td>{r.overall_rank}</td>
											<td>{r.overall_points.toFixed(2)}</td>
											<td>{r.swim_rank}</td>
											<td>{r.swim_points.toFixed(2)}</td>
											<td>{r.bike_rank}</td>
											<td>{r.bike_points.toFixed(2)}</td>
											<td>{r.run_rank}</td>
											<td>{r.run_points.toFixed(2)}</td>
										</tr>)
									})}
								</table>
							</div>
						</Fragment>
					}
				</div>

				<Dialog yesText="Submit" title="Submit Athlete Picture" ref={this.dialogSubmitAthletePic} onYes={this.dialogSaveProfileImage} onShow={this.dialogOnShow} onHide={this.dialogOnHide}>
					<div class='edit-image' ref={this.editImageSubmitAthletePic} data-id={athlete.id}>
						<div class='transform-image' ref={this.transformImageSubmitAthletePic}>
							<img style='width: 100%; height: 100%;' crossorigin="anonymous" ref={this._imageSubmitAthletePic} onLoad={this.handleOnLoad}/>
						</div>
						<div class='mask' ref={this.maskSubmitAthletePic}>
							<img style='width: 100%; height: 100%;' src='/assets/profile_mask.png' style='opacity: 0.4; display: none;'/>
							<div style='width: 1px; left: 50%; top: 0; height: 100%; position: absolute; background-color: rgba(255,255,255,0.4);'></div>
							<div style='width: 100%; left: 0; top: 35%; height: 1px; position: absolute; background-color: rgba(255,255,255,0.4);'></div>
							<div style='display: none; width: 1px; left: 40%; top: 35%; height: 6%; position: absolute; background-color: rgba(255,255,255,0.4);'></div>
							<div style='display: none; width: 1px; left: 60%; top: 35%; height: 6%; position: absolute; background-color: rgba(255,255,255,0.4);'></div>
						</div>
					</div>
					<div class='d-flex justify-content-center align-items-center w-100 pt-4'>
						<div class='w-25'>
							<button class='btn btn-sm btn-secondary' onClick={this.removebg}>Remove Background</button>
							<button class='btn btn-sm btn-secondary ml-1' onClick={this.autocenter}>Auto Scale</button>
						</div>
						<div class='w-50 d-flex justify-content-center align-items-center'>
							<div class='material-icons-round mr-2' style='font-size: 16px;'>search</div>
							<input type="range" min="50" max="300" value="100" class='w-100' ref={this.scaleSubmitAthletePic} onInput={this.handleOnInputScale}/>
							<div class='material-icons-round ml-2' style='font-size: 32px;'>search</div>
						</div>
						<div class='w-25'>
						</div>
					</div>
				</Dialog>

				<PleaseWait show={state.working}/>
			</div>
		)
	}
}
